import React from "react";
import { graphql } from "gatsby";
import Button from "../components/Button";
import HeroContainer from "../components/HeroContainer";
import SEO from "../components/SEO";

const NotFound = (props: any) => (
  <>
    <SEO {...props.data.prismicSeo.data} />
    <div className="hero-error gradient">
      <HeroContainer
        type="hero-error"
        title="We're sorry, but we coudn't find the page you're looking for."
        titleClass="large"
        paragraph="It might be an old link, or maybe it moved. Before you go you can check the url for misspells and try again, or simply head back to our home page."
        paragraphClass="p1 large"
        cap="404"
      >
        <Button
          buttonClass="button w-button"
          buttonHref="/services"
          buttonLabel="go to home page"
          buttonLine="button-box"
        />
      </HeroContainer>
      <div className="animation-container">
        <div className="rec-1" />
        <div className="rec-2" />
        <div className="rec-3" />
        <div className="rec-4" />
        <div className="rec-5" />
      </div>
    </div>
  </>
);

export default NotFound;

export const pageQuery = graphql`
  query NotFoundQuery {
    prismicSeo(data: { name: { eq: "404" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
  }
`;
